<template>
  <div class="js-common-wrap js-operation-details">
    <div class="js-common-head">
      <div>
        <el-button
          class="back-btn"
          @click="$goBack"
          icon="el-icon-arrow-left"
          size="small"
          type="primary"
          plain
        ></el-button>
        <el-radio-group
          class="label"
          v-model="type"
          size="small"
          @change="handleScrollTop"
        >
          <el-radio-button :label="1">作业详情</el-radio-button>
          <el-radio-button :label="0">作业报告</el-radio-button>
        </el-radio-group>

        <el-badge value="new" class="item">
          <el-button style="margin-left: 20px" @click="showRating"
            >评分</el-button
          >
        </el-badge>
        <AddTags
          :video-url="detailsData.videoUrl"
          :id="detailsData.id"
          type="standard"
          :show-tags="detailsData.tags"
          @after-edit="getDetails"
        />
      </div>
      <el-button
        v-if="!type"
        type="primary"
        size="small"
        @click="handleDownload"
      >
        下载 <i class="el-icon-download"></i>
      </el-button>
    </div>
    <div v-if="type" class="js-common-content" ref="detailsContent">
      <div class="details-box details-box-base">
        <div class="img-box">
          <img
            class="video"
            src="@/assets/images/9.png"
            alt=""
            @click="showVideo"
          />
          <img class="img" :src="detailsData.thumbUrl" alt="" />
        </div>
        <ul class="base-infos">
          <li class="title">{{ detailsData.name }}</li>
          <li class="subInfo">
            <div>
              <label>时长</label>
              <p>{{ detailsData.duration }}</p>
            </div>
            <div>
              <label>项目检查</label>
              <p>
                {{ detailsData.checkCount }} / {{ detailsData.needCheckCount }}
              </p>
            </div>
            <div>
              <label>标记数</label>
              <p>{{ detailsData.markCount }}</p>
            </div>
          </li>
          <li><label>识别码：</label>{{ detailsData.code }}</li>
          <li><label>作业人：</label>{{ detailsData.createdName }}</li>
          <li><label>关联标准作业：</label>{{ detailsData.sopName }}</li>
          <li><label>开始时间：</label>{{ detailsData.startDate }}</li>
          <li><label>结束时间：</label>{{ detailsData.endDate }}</li>
          <li><label>记录ID：</label>{{ detailsData.id }}</li>
          <li>
            <label>评分：</label
            ><span v-for="(rate, index) in gradeList" :key="index">{{
              `${rate.raterUserName}给${rate.rateeUserName}评分为${rate.grade}；`
            }}</span>
          </li>
          <li><label>备注：</label>{{ detailsData.remark }}</li>
        </ul>
      </div>
      <div class="details-box details-box2" v-show="isShow">
        <div class="details-box-tlt">作业效率分析</div>
        <div class="subInfo">
          <div class="item">
            <label>内容</label>
            <p>{{ currentBarInfo.content }}</p>
          </div>
          <div class="item">
            <label>时长</label>
            <p>{{ (currentBarInfo.useTime * 1000) | timesToFilter }}</p>
          </div>
          <div class="item">
            <label>开始时间</label>
            <p>{{ currentBarInfo.startDate }}</p>
          </div>
          <div class="item">
            <label>结束时间</label>
            <p>{{ currentBarInfo.endDate }}</p>
          </div>
        </div>
        <div class="echarts-box" v-if="showBarChart">
          <echarts-component
            :options="barChart"
            @barParams="barParams"
          ></echarts-component>
        </div>
        <div>
          <span>蓝:计划时长</span><br />
          <span>绿:实际使用时长</span>
        </div>
      </div>
      <div class="details-box details-box3">
        <div class="details-box-tlt">作业位置</div>
        <baidu-map
          :initMap="initMap"
          :showCall="false"
          :polylinesArr="polylines"
          :center="center"
          :scrollWheelZoom="false"
        ></baidu-map>
      </div>
      <div class="details-box details-box4">
        <div class="details-box-tlt">作业流程报告</div>
        <div class="time-line-box">
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in detailsData.sectionList"
              :key="index"
              :icon="infoRecord.icon"
              :type="infoRecord.type"
              :size="infoRecord.size"
            >
              <div class="time-line-tlt">
                <label>{{ item.sopSectionName }}：</label>
                <span>{{ item.gmtCreated }}</span>
                <label>时长：</label>
                <span>{{ item.useTime }}</span>
                <!-- <label>开始位置：</label>
                <span>{{ item.address }}</span> -->
              </div>
              <div class="time-line-detail">
                <p class="time-line-name">信息记录结果：</p>
                <ul>
                  <li v-for="(step, d) in item.stepList" :key="d">
                    <img :src="step.confirmPicUrl" alt="" />
                    <div class="info">
                      <p>
                        <i class="el-icon-edit-outline"></i>
                        <span class="time">{{ step.gmtCreated }}</span>
                      </p>
                      <p>{{ step.confirmText }}</p>
                      <!-- <p>
                        <i class="el-icon-location-outline"></i>
                        <span class="addr">{{ info.addr }}</span>
                      </p> -->
                    </div>
                  </li>
                </ul>
              </div>
              <div class="time-line-detail">
                <p class="time-line-name">打点过程记录：</p>
                <ul>
                  <li v-for="(mark, m) in item.markList" :key="m">
                    <img :src="mark.markPicUrl" alt="" />
                    <div class="info">
                      <p>
                        <i class="el-icon-edit-outline"></i>
                        <span class="time">{{ mark.gmtCreated }}</span>
                      </p>
                      <p>{{ mark.description }}</p>
                      <!-- <p>
                        <i class="el-icon-location-outline"></i>
                        <span class="addr">{{ info.addr }}</span>
                      </p> -->
                    </div>
                  </li>
                </ul>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <div v-else ref="reportBox" class="js-common-content js-operation-report">
      <div ref="downloadWrapper">
        <div class="report-title">
          <label>{{ reportInfo.name }}</label>
          <img ref="logoUrl" :src="reportInfo.logoUrl" alt="" />
        </div>
        <ul class="baseinfo">
          <li>
            <img src="@/assets/images/10.png" alt="" />
            <div class="box">
              <p>时长</p>
              <p class="num">{{ reportInfo.duration }}</p>
            </div>
          </li>
          <li>
            <img src="@/assets/images/11.png" alt="" />
            <div class="box">
              <p>检查项目</p>
              <p class="num">
                {{ reportInfo.checkCount }} / {{ reportInfo.needCheckCount }}
              </p>
            </div>
          </li>
          <li>
            <img src="@/assets/images/12.png" alt="" />
            <div class="box">
              <p>标记数</p>
              <p class="num">{{ reportInfo.markCount }}</p>
            </div>
          </li>
        </ul>
        <ul class="userinfo">
          <li class="userinfo-avatar">
            <div>
              <p class="title">作业人</p>
              <img src="@/assets/images/userImg.png" alt="" />
            </div>
            <div class="list">
              <p>
                账号：<span class="val">{{ reportInfo.account }}</span>
              </p>
              <p>
                姓名：<span class="val">{{ reportInfo.createdName }}</span>
              </p>
              <p>
                备注：<span class="val">{{ reportInfo.remark }}</span>
              </p>
            </div>
          </li>
          <li class="list">
            <p>
              识别码：<span class="val">{{ reportInfo.code }}</span>
            </p>
            <p>
              关联标准作业：<span class="val">{{ reportInfo.sopName }}</span>
            </p>
            <p>
              记录ID：<span class="val">{{ reportInfo.id }}</span>
            </p>
          </li>
          <li class="list">
            <el-timeline>
              <el-timeline-item :color="activities.color">
                <label>开始时间：</label>
                <span class="val">{{ reportInfo.startDate }}</span>
              </el-timeline-item>
              <el-timeline-item :color="activities.color">
                <label>结束时间：</label>
                <span class="val">{{ reportInfo.endDate }}</span>
              </el-timeline-item>
            </el-timeline>
          </li>
        </ul>
        <div class="operation-record-form">
          <div class="title">作业记录表</div>
          <div
            class="steps"
            v-for="(item, index) in reportInfo.sectionList"
            :key="index"
          >
            <h4>{{ item.sectionName }}</h4>
            <ul>
              <li v-for="(step, subIndex) in item.stepList" :key="subIndex">
                <label
                  >{{ formatResultType(step.resultType) }}：{{
                    step.resultJson
                  }}</label
                >
                <span class="line"></span>
                <span>{{ step.confirmText }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <video-list
      v-model="videoListFlag"
      :videoInfos="detailsData.videoMarkList"
      :videoUrl="detailsData.videoUrl"
    ></video-list>
    <Rating v-model="show.rating" type="standard" />
  </div>
</template>
<script>
import BaiduMap from "@/components/BaiduMap";
import EchartsComponent from "@/components/EchartsComponent";
import VideoList from "@/components/VideoList";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Rating from "@/components/Rating";
import AddTags from "@/components/AddTags";

export default {
  name: "",
  components: {
    BaiduMap,
    EchartsComponent,
    VideoList,
    Rating,
    AddTags,
  },
  data() {
    return {
      show: { rating: false },
      type: 1,
      isShow: true,
      gradeList: [],
      barChart: {
        xAxis: [
          {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            triggerEvent: true,
          },
        ],
        series: [
          {
            name: "计划时间",
            type: "bar",
            barWidth: 10,
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3,
            ],
          },
          {
            name: "实际用时",
            type: "bar",
            barWidth: 10,
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
        ],
      },
      infoRecord: {
        size: "large",
        type: "primary",
        color: "#20bd88",
      },
      videoListFlag: false,
      reportName: "张三打麻将",
      activities: {
        color: "#20bd88",
      },
      currentID: "",
      detailsData: {
        name: "",
        duration: "",
        videoUrl: "",
        videoMarkList: [],
      },
      htmlTitle: "作业报告PDF文件",
      polylines: [
        {
          gpsList: [],
        },
      ],
      center: { lng: 116.404, lat: 39.915 },
      showBarChart: false,
      currentBarInfo: {
        content: "",
        useTime: 0,
        startDate: "",
        endDate: "",
      },
      reportInfo: {},
      initMap: false,
    };
  },
  mounted() {
    this.currentID = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    showRating() {
      this.show.rating = true;
    },
    getDetails() {
      this.$axios({
        method: "get",
        url: `api/csp/sop/v1/sopRecord/detail/${this.currentID}`,
      }).then((res) => {
        const data = res.data;

        if (!data.status) {
          this.detailsData = data.data;
          this.detailsData.tags = data.data.tags ? data.data.tags.split(",") : [];

          this.gradeList = data.data.gradeList;
          this.polylines[0].gpsList = [];
          if (this.detailsData.startLon && this.detailsData.startLat) {
            this.polylines[0].gpsList.push({
              lng: this.detailsData.startLon,
              lat: this.detailsData.startLat,
            });
          }
          if (this.detailsData.endLon && this.detailsData.endLat) {
            this.polylines[0].gpsList.push({
              lng: this.detailsData.endLon,
              lat: this.detailsData.endLat,
            });
          }
          if (this.polylines[0].gpsList.length) {
            this.center =
              this.polylines[0].gpsList[this.polylines[0].gpsList.length - 1];
          }

          this.initMap = true;
          let xAxis = [];
          let y1 = [];
          let y2 = [];
          let list = this.detailsData.efficientList;
          if (list === null) {
            // 隐藏界面
            this.isShow = false;
          } else {
            this.isShow = true;
            if (list.length) {
              this.currentBarInfo = list[0];
            }
            for (let item in list) {
              xAxis.push(parseInt(item) + 1);
              y1.push(list[item].stepTime);
              y2.push(list[item].useTime);
            }
          }

          this.getBarChart(xAxis, y1, y2);
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    showVideo() {
      this.videoListFlag = true;
    },
    getPdf() {
      let title = this.htmlTitle;
      html2canvas(this.$refs.downloadWrapper, {
        useCORS: true,
      }).then(function (canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new jsPDF("", "pt", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + ".pdf");
      });
    },
    handleDownload() {
      if (!this.type) {
        this.getPdf();
      } else {
        this.$message({
          type: "error",
          message: "暂无数据可供下载！",
          duration: 1000,
        });
      }
    },
    getBarChart(x, y1, y2) {
      this.barChart.xAxis[0].data = x;
      this.barChart.series[0].data = y1;
      this.barChart.series[1].data = y2;
      this.showBarChart = true;
    },
    barParams(params) {
      this.currentBarInfo = this.detailsData.efficientList[params.dataIndex];
    },
    handleScrollTop() {
      this.$nextTick(() => {
        if (this.type) {
          this.$refs.detailsContent.scrollTop = 0;
          this.getDetails();
        } else {
          this.initMap = false;
          this.$refs.reportBox.scrollTop = 0;
          this.getReport();
        }
      });
    },
    getReport() {
      this.$axios({
        method: "get",
        url: `api/csp/sop/v1/sopRecord/report/${this.currentID}`,
      }).then((res) => {
        const data = res.data;

        if (!data.status) {
          this.reportInfo = data.data;
          if (this.reportInfo.logoUrl) {
            this.img2base64(this.reportInfo.logoUrl, "logoUrl");
          }
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    img2base64(url, ref) {
      let that = this;
      let image = new Image();
      image.src = url + "?v=" + Math.random(); // 处理缓存
      image.crossOrigin = "*"; // 支持跨域图片
      image.onload = function () {
        let base64 = that.drawBase64Image(image);
        that.$refs[ref].src = base64;
      };
    },
    drawBase64Image(img) {
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let dataURL = canvas.toDataURL("image/png");
      return dataURL;
    },
    formatResultType(type) {
      if (type == 1) {
        return "文字";
      } else if (type == 2) {
        return "选择";
      } else if (type == 3) {
        return "数值";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/operationDetails.scss";
</style>
