<template>
  <el-dialog class="js-dialog-form js-dialog-video" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1100px">
    <div class="js-dialog-content">
      <div class="player-box">
        <div id="playerDOM" class="playerDom"></div>
      </div>
      <ul class="list">
        <li class="clearfix" v-for="item in videoInfos" :key="item.id" @click="setCurrentTime(item.progressTime)">
          <img :src="item.markPicUrl" alt="">
          <div>
            <p>时间：{{ item.gmtCreated }}</p>
            <p>{{ item.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
import Player from 'xgplayer'
export default {
  name: '',
  props: ['value','videoUrl','videoInfos'],
  data() {
    return {
      player: '',
      list: [{
        id: 1,
        time: '14:36:22',
        desc: '记录了吧啊他到下一步啊，今天拍下一部之后过来到下一步'
      }]
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  watch:{
    dialogVisible(newValue,oldValue){
      if(newValue){
        this.$nextTick(()=>{
          this.createPlayer()
        })
      }else{
        this.player.destroy()
      }
    }
  },
  mounted() {},
  methods: {
    createPlayer(){
      let dom = document.getElementById('playerDOM')
      this.player = new Player({
        el: dom,
        url: this.videoUrl,
        autoplay: true
      })
    },
    setCurrentTime(time){
      this.player.currentTime = time
    }
  }
}
</script>

<style lang="scss" scoped>

</style>