var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form js-dialog-video",
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "1100px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "js-dialog-content" }, [
        _c("div", { staticClass: "player-box" }, [
          _c("div", { staticClass: "playerDom", attrs: { id: "playerDOM" } }),
        ]),
        _c(
          "ul",
          { staticClass: "list" },
          _vm._l(_vm.videoInfos, function (item) {
            return _c(
              "li",
              {
                key: item.id,
                staticClass: "clearfix",
                on: {
                  click: function ($event) {
                    return _vm.setCurrentTime(item.progressTime)
                  },
                },
              },
              [
                _c("img", { attrs: { src: item.markPicUrl, alt: "" } }),
                _c("div", [
                  _c("p", [_vm._v("时间：" + _vm._s(item.gmtCreated))]),
                  _c("p", [_vm._v(_vm._s(item.description))]),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }