var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-operation-details" },
    [
      _c(
        "div",
        { staticClass: "js-common-head" },
        [
          _c(
            "div",
            [
              _c("el-button", {
                staticClass: "back-btn",
                attrs: {
                  icon: "el-icon-arrow-left",
                  size: "small",
                  type: "primary",
                  plain: "",
                },
                on: { click: _vm.$goBack },
              }),
              _c(
                "el-radio-group",
                {
                  staticClass: "label",
                  attrs: { size: "small" },
                  on: { change: _vm.handleScrollTop },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("作业详情"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 0 } }, [
                    _vm._v("作业报告"),
                  ]),
                ],
                1
              ),
              _c(
                "el-badge",
                { staticClass: "item", attrs: { value: "new" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      on: { click: _vm.showRating },
                    },
                    [_vm._v("评分")]
                  ),
                ],
                1
              ),
              _c("AddTags", {
                attrs: {
                  "video-url": _vm.detailsData.videoUrl,
                  id: _vm.detailsData.id,
                  type: "standard",
                  "show-tags": _vm.detailsData.tags,
                },
                on: { "after-edit": _vm.getDetails },
              }),
            ],
            1
          ),
          !_vm.type
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleDownload },
                },
                [_vm._v(" 下载 "), _c("i", { staticClass: "el-icon-download" })]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.type
        ? _c(
            "div",
            { ref: "detailsContent", staticClass: "js-common-content" },
            [
              _c("div", { staticClass: "details-box details-box-base" }, [
                _c("div", { staticClass: "img-box" }, [
                  _c("img", {
                    staticClass: "video",
                    attrs: { src: require("@/assets/images/9.png"), alt: "" },
                    on: { click: _vm.showVideo },
                  }),
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: _vm.detailsData.thumbUrl, alt: "" },
                  }),
                ]),
                _c("ul", { staticClass: "base-infos" }, [
                  _c("li", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.detailsData.name)),
                  ]),
                  _c("li", { staticClass: "subInfo" }, [
                    _c("div", [
                      _c("label", [_vm._v("时长")]),
                      _c("p", [_vm._v(_vm._s(_vm.detailsData.duration))]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("项目检查")]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.detailsData.checkCount) +
                            " / " +
                            _vm._s(_vm.detailsData.needCheckCount) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("标记数")]),
                      _c("p", [_vm._v(_vm._s(_vm.detailsData.markCount))]),
                    ]),
                  ]),
                  _c("li", [
                    _c("label", [_vm._v("识别码：")]),
                    _vm._v(_vm._s(_vm.detailsData.code)),
                  ]),
                  _c("li", [
                    _c("label", [_vm._v("作业人：")]),
                    _vm._v(_vm._s(_vm.detailsData.createdName)),
                  ]),
                  _c("li", [
                    _c("label", [_vm._v("关联标准作业：")]),
                    _vm._v(_vm._s(_vm.detailsData.sopName)),
                  ]),
                  _c("li", [
                    _c("label", [_vm._v("开始时间：")]),
                    _vm._v(_vm._s(_vm.detailsData.startDate)),
                  ]),
                  _c("li", [
                    _c("label", [_vm._v("结束时间：")]),
                    _vm._v(_vm._s(_vm.detailsData.endDate)),
                  ]),
                  _c("li", [
                    _c("label", [_vm._v("记录ID：")]),
                    _vm._v(_vm._s(_vm.detailsData.id)),
                  ]),
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("评分：")]),
                      _vm._l(_vm.gradeList, function (rate, index) {
                        return _c("span", { key: index }, [
                          _vm._v(
                            _vm._s(
                              `${rate.raterUserName}给${rate.rateeUserName}评分为${rate.grade}；`
                            )
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                  _c("li", [
                    _c("label", [_vm._v("备注：")]),
                    _vm._v(_vm._s(_vm.detailsData.remark)),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShow,
                      expression: "isShow",
                    },
                  ],
                  staticClass: "details-box details-box2",
                },
                [
                  _c("div", { staticClass: "details-box-tlt" }, [
                    _vm._v("作业效率分析"),
                  ]),
                  _c("div", { staticClass: "subInfo" }, [
                    _c("div", { staticClass: "item" }, [
                      _c("label", [_vm._v("内容")]),
                      _c("p", [_vm._v(_vm._s(_vm.currentBarInfo.content))]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("label", [_vm._v("时长")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm._f("timesToFilter")(
                              _vm.currentBarInfo.useTime * 1000
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("label", [_vm._v("开始时间")]),
                      _c("p", [_vm._v(_vm._s(_vm.currentBarInfo.startDate))]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("label", [_vm._v("结束时间")]),
                      _c("p", [_vm._v(_vm._s(_vm.currentBarInfo.endDate))]),
                    ]),
                  ]),
                  _vm.showBarChart
                    ? _c(
                        "div",
                        { staticClass: "echarts-box" },
                        [
                          _c("echarts-component", {
                            attrs: { options: _vm.barChart },
                            on: { barParams: _vm.barParams },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._m(0),
                ]
              ),
              _c(
                "div",
                { staticClass: "details-box details-box3" },
                [
                  _c("div", { staticClass: "details-box-tlt" }, [
                    _vm._v("作业位置"),
                  ]),
                  _c("baidu-map", {
                    attrs: {
                      initMap: _vm.initMap,
                      showCall: false,
                      polylinesArr: _vm.polylines,
                      center: _vm.center,
                      scrollWheelZoom: false,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "details-box details-box4" }, [
                _c("div", { staticClass: "details-box-tlt" }, [
                  _vm._v("作业流程报告"),
                ]),
                _c(
                  "div",
                  { staticClass: "time-line-box" },
                  [
                    _c(
                      "el-timeline",
                      _vm._l(
                        _vm.detailsData.sectionList,
                        function (item, index) {
                          return _c(
                            "el-timeline-item",
                            {
                              key: index,
                              attrs: {
                                icon: _vm.infoRecord.icon,
                                type: _vm.infoRecord.type,
                                size: _vm.infoRecord.size,
                              },
                            },
                            [
                              _c("div", { staticClass: "time-line-tlt" }, [
                                _c("label", [
                                  _vm._v(_vm._s(item.sopSectionName) + "："),
                                ]),
                                _c("span", [_vm._v(_vm._s(item.gmtCreated))]),
                                _c("label", [_vm._v("时长：")]),
                                _c("span", [_vm._v(_vm._s(item.useTime))]),
                              ]),
                              _c("div", { staticClass: "time-line-detail" }, [
                                _c("p", { staticClass: "time-line-name" }, [
                                  _vm._v("信息记录结果："),
                                ]),
                                _c(
                                  "ul",
                                  _vm._l(item.stepList, function (step, d) {
                                    return _c("li", { key: d }, [
                                      _c("img", {
                                        attrs: {
                                          src: step.confirmPicUrl,
                                          alt: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "info" }, [
                                        _c("p", [
                                          _c("i", {
                                            staticClass: "el-icon-edit-outline",
                                          }),
                                          _c("span", { staticClass: "time" }, [
                                            _vm._v(_vm._s(step.gmtCreated)),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v(_vm._s(step.confirmText)),
                                        ]),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]),
                              _c("div", { staticClass: "time-line-detail" }, [
                                _c("p", { staticClass: "time-line-name" }, [
                                  _vm._v("打点过程记录："),
                                ]),
                                _c(
                                  "ul",
                                  _vm._l(item.markList, function (mark, m) {
                                    return _c("li", { key: m }, [
                                      _c("img", {
                                        attrs: {
                                          src: mark.markPicUrl,
                                          alt: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "info" }, [
                                        _c("p", [
                                          _c("i", {
                                            staticClass: "el-icon-edit-outline",
                                          }),
                                          _c("span", { staticClass: "time" }, [
                                            _vm._v(_vm._s(mark.gmtCreated)),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v(_vm._s(mark.description)),
                                        ]),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _c(
            "div",
            {
              ref: "reportBox",
              staticClass: "js-common-content js-operation-report",
            },
            [
              _c("div", { ref: "downloadWrapper" }, [
                _c("div", { staticClass: "report-title" }, [
                  _c("label", [_vm._v(_vm._s(_vm.reportInfo.name))]),
                  _c("img", {
                    ref: "logoUrl",
                    attrs: { src: _vm.reportInfo.logoUrl, alt: "" },
                  }),
                ]),
                _c("ul", { staticClass: "baseinfo" }, [
                  _c("li", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/10.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "box" }, [
                      _c("p", [_vm._v("时长")]),
                      _c("p", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.reportInfo.duration)),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/11.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "box" }, [
                      _c("p", [_vm._v("检查项目")]),
                      _c("p", { staticClass: "num" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.reportInfo.checkCount) +
                            " / " +
                            _vm._s(_vm.reportInfo.needCheckCount) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/12.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "box" }, [
                      _c("p", [_vm._v("标记数")]),
                      _c("p", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.reportInfo.markCount)),
                      ]),
                    ]),
                  ]),
                ]),
                _c("ul", { staticClass: "userinfo" }, [
                  _c("li", { staticClass: "userinfo-avatar" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "list" }, [
                      _c("p", [
                        _vm._v(" 账号："),
                        _c("span", { staticClass: "val" }, [
                          _vm._v(_vm._s(_vm.reportInfo.account)),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v(" 姓名："),
                        _c("span", { staticClass: "val" }, [
                          _vm._v(_vm._s(_vm.reportInfo.createdName)),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v(" 备注："),
                        _c("span", { staticClass: "val" }, [
                          _vm._v(_vm._s(_vm.reportInfo.remark)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("li", { staticClass: "list" }, [
                    _c("p", [
                      _vm._v(" 识别码："),
                      _c("span", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.reportInfo.code)),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v(" 关联标准作业："),
                      _c("span", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.reportInfo.sopName)),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v(" 记录ID："),
                      _c("span", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.reportInfo.id)),
                      ]),
                    ]),
                  ]),
                  _c(
                    "li",
                    { staticClass: "list" },
                    [
                      _c(
                        "el-timeline",
                        [
                          _c(
                            "el-timeline-item",
                            { attrs: { color: _vm.activities.color } },
                            [
                              _c("label", [_vm._v("开始时间：")]),
                              _c("span", { staticClass: "val" }, [
                                _vm._v(_vm._s(_vm.reportInfo.startDate)),
                              ]),
                            ]
                          ),
                          _c(
                            "el-timeline-item",
                            { attrs: { color: _vm.activities.color } },
                            [
                              _c("label", [_vm._v("结束时间：")]),
                              _c("span", { staticClass: "val" }, [
                                _vm._v(_vm._s(_vm.reportInfo.endDate)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "operation-record-form" },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("作业记录表")]),
                    _vm._l(_vm.reportInfo.sectionList, function (item, index) {
                      return _c("div", { key: index, staticClass: "steps" }, [
                        _c("h4", [_vm._v(_vm._s(item.sectionName))]),
                        _c(
                          "ul",
                          _vm._l(item.stepList, function (step, subIndex) {
                            return _c("li", { key: subIndex }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatResultType(step.resultType)
                                  ) +
                                    "：" +
                                    _vm._s(step.resultJson)
                                ),
                              ]),
                              _c("span", { staticClass: "line" }),
                              _c("span", [_vm._v(_vm._s(step.confirmText))]),
                            ])
                          }),
                          0
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
      _c("video-list", {
        attrs: {
          videoInfos: _vm.detailsData.videoMarkList,
          videoUrl: _vm.detailsData.videoUrl,
        },
        model: {
          value: _vm.videoListFlag,
          callback: function ($$v) {
            _vm.videoListFlag = $$v
          },
          expression: "videoListFlag",
        },
      }),
      _c("Rating", {
        attrs: { type: "standard" },
        model: {
          value: _vm.show.rating,
          callback: function ($$v) {
            _vm.$set(_vm.show, "rating", $$v)
          },
          expression: "show.rating",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", [_vm._v("蓝:计划时长")]),
      _c("br"),
      _c("span", [_vm._v("绿:实际使用时长")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "title" }, [_vm._v("作业人")]),
      _c("img", {
        attrs: { src: require("@/assets/images/userImg.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }